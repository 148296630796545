import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import pjson from './../package.json';

/*Sentry Config*/
Sentry.init({
    dsn: 'https://6caaff4c43b38f0e44b91d848d1b513a@sentry-relay.penpencil.co/62',
    environment: environment.name,
    release: pjson.version,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],

    // Capture 100% of transactions for tracing
    tracesSampleRate: 1.0,

    // Capture Replay for 5% of all sessions
    replaysSessionSampleRate: 0.05,

    // Capture Replay for 5% of sessions with an error
    replaysOnErrorSampleRate: 0.05,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
