import { Injectable } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { GlobalService } from '../global/global.service';
import {
    QUESTION_TYPES,
    VideoTypes,
} from '../global-constant/global-constant.service';
import {
    SECURE_VIDEO_URL_REGEX,
    YOU_TUBE_VIDEO_URL_REGEX,
} from '../../configurations/regex';
import { environment } from '../../../../environments/environment';
import * as QRCode from 'qrcode';
import { franc } from 'franc';
import { FormControl } from '@angular/forms';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    Observable,
    switchMap,
} from 'rxjs';
import * as LZString from 'lz-string';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor(
        private storageService: StorageService,
        private globalService: GlobalService
    ) {}

    splitLastWord(string: string, symbol: string): string {
        return string?.substring(0, string?.lastIndexOf(symbol));
    }

    findPageNumber = (targetArray: any) => {
        return +(targetArray.length / 20).toFixed();
    };

    splitWord(
        string: string,
        symbol: string
    ): { firstPart: string; secondPart: string } {
        const lastIndex = string?.lastIndexOf(symbol);

        if (lastIndex !== -1) {
            const firstPart = string?.substring(0, lastIndex);
            const secondPart = string?.substring(lastIndex + 1);

            return { firstPart, secondPart };
        } else {
            // If the symbol is not found, return the entire string
            // as the first part, and an empty string as the second part.
            return { firstPart: string || '', secondPart: '' };
        }
    }

    splitStringIntoArray(value: string, symbol: string): Array<string> {
        if (!value?.length) {
            return [];
        }
        const splitIds: string[] = value.split(symbol);
        const uniqueId: string[] = [...new Set(splitIds)];

        // Filter out empty strings
        const filteredIds: string[] = uniqueId.filter((id) => id.trim() !== '');

        return filteredIds;
    }

    downloadFile(url: any): void {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    }

    isUserCameFromAcademyAdmin(): boolean {
        return (
            this.storageService.getSourceApp() ===
            this.storageService.ACADEMY_ADMIN
        );
    }

    isUserCameFromPPAdmin(): boolean {
        return (
            this.storageService.getSourceApp() === this.storageService.PP_ADMIN
        );
    }

    copyToClipboard(element: string, message: string) {
        const listener = (event: ClipboardEvent) => {
            event.clipboardData?.setData('text/plain', element);
            event.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);

        this.globalService.showSnackBar(message, 'Ok');
    }

    /** Generate Video QR Code URL **/
    async generateQRCodeURL(url: string): Promise<string> {
        const urlType: string = SECURE_VIDEO_URL_REGEX.test(url)
            ? VideoTypes.SECURE_VIDEO
            : VideoTypes.YOU_TUBE_VIDEO;

        const videoUrl = `${environment.videoBaseUrl}?src=${url}&type=${urlType}&three_dots=false&is_products_enabled=false`;
        return await this.generateQRCode(videoUrl);
    }

    /** Get Video Url Type **/
    getVideoUrlType(url: any) {
        if (YOU_TUBE_VIDEO_URL_REGEX.test(url)) {
            return 'youtube';
        } else if (url?.toString().includes('master.mpd')) {
            return 'penpencilvdo';
        } else {
            return '';
        }
    }

    /** Generate QR Code **/
    generateQRCode(url: string): Promise<string> {
        return QRCode.toDataURL(url, {
            width: 150,
            margin: 1,
            color: {
                dark: '#000',
                light: '#FFF',
            },
        });
    }

    /** Detect Language Code **/
    detectLanguage(text: string): string {
        const langCode = franc(text, {
            only: ['eng', 'hin', 'mar', 'guj', 'kan', 'ben', 'tel'],
        });
        return langCode;
    }

    /** Strip HTML Tags **/
    stripHtml(html: string): string {
        if (!html) {
            return '';
        }
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    /** Initialize Search Control with debounce **/
    initializeSearch<T>(
        key: string,
        control: FormControl,
        onSearch: (key: string, searchTerm: string, index: number) => void,
        index: number
    ): Observable<void> {
        return control.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            filter((text) => text !== null && text !== undefined),
            switchMap(async (searchTerm) => {
                onSearch(key, searchTerm?.trim(), index);
                return;
            })
        );
    }

    /** Compress Data **/
    compressData(data: any): string {
        try {
            return LZString.compressToUTF16(JSON.stringify(data));
        } catch (error) {
            console.error('Compression failed', error);
            return '';
        }
    }

    /** Decompress Data **/
    decompressData(compressedData: string): any {
        try {
            const decompressedString =
                LZString.decompressFromUTF16(compressedData);
            return JSON.parse(decompressedString);
        } catch (error) {
            console.error('Decompression failed', error);
            return null;
        }
    }

    isComprehensiveType(type: number): boolean {
        return (
            type === QUESTION_TYPES.COMPREHENSION ||
            type === QUESTION_TYPES.CASE_STUDY
        );
    }
}
