export const BASE_URLs = {
    production: 'https://api.penpencil.co/',
    staging: 'https://stage-api.penpencil.co/',
    development: 'https://dev-api.penpencil.co/',
};

export const ENVIRONMENT_VARIABLES_PRODUCTION = {
    staticCdnUrl: 'https://static.pw.live/',
    pwOrgId: '5eb393ee95fab7468a79d189',
    isUsernameLoginEnabled: false,
    docxportUrl: 'https://qbg-docxport.penpencil.co',
    videoBaseUrl: 'https://www.pw.live/watch/',
    name: 'production',
    SENTRY_AUTH_TOKEN:
        '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',
};

export const ENVIRONMENT_VARIABLES_STAGING = {
    staticCdnUrl: 'https://static.pw.live/',
    pwOrgId: '5eb393ee95fab7468a79d189',
    isUsernameLoginEnabled: true,
    docxportUrl: 'https://qbg-docxport-stage.penpencil.co',
    videoBaseUrl: 'https://staging.physicswallah.live/watch/',
    name: 'staging',
    SENTRY_AUTH_TOKEN:
        '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',
};

export const ENVIRONMENT_VARIABLES_DEVELOPMENT = {
    staticCdnUrl: 'https://static.pw.live/',
    pwOrgId: '5eb393ee95fab7468a79d189',
    isUsernameLoginEnabled: true,
    docxportUrl: 'https://qbg-docxport-dev.penpencil.co',
    videoBaseUrl: 'https://pw-study-dev.penpencil.co/watch/',
    name: 'development',
    SENTRY_AUTH_TOKEN:
        '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',
};
